
export default {
    name: 'FinishRegister',
    data() {
        return {
            // 选中步骤
            active: 0,
            // 表单一数据
            form1: {
                company_name: ''
            },
            // 表单一验证规则
            rules1: {
                company_name: [{
                    required: true,
                    message: this.$t('user.activation.tips.company_name'),
                    type: 'string',
                    trigger: 'blur'
                }]
            },
            // 步骤一提交状态
            loading1: false,
            // 表单二数据
            form2: {
                contact_name: '',
                contact_email: '',
                contact_tel: '',
            },
            // 表单二验证规则
            rules2: {
                contact_name: [{
                    required: true,
                    message: this.$t('user.activation.tips.contact_name'),
                    type: 'string',
                    trigger: 'blur'
                }],
                contact_email: [{
                    required: true,
                    message: this.$t('user.activation.tips.contact_email'),
                    type: 'string',
                    trigger: 'blur'
                }],
                contact_tel: [{
                    required: true,
                    message: this.$t('user.activation.tips.contact_tel'),
                    type: 'string',
                    trigger: 'blur'
                }]
            },
            // 步骤二提交状态
            loading2: false
        };
    },
    methods: {
        /* 步骤一提交 */
        submit1() {
            this.$refs.form1.validate().then(() => {
                this.loading1 = true;
                setTimeout(() => {
                    this.loading1 = false;
                    this.active = 1;
                }, 300);
            }).catch(() => {});
        },
        /* 步骤二提交 */
        submit2() {
            this.$refs.form2.validate().then(() => {
                this.loading2 = true;

                var body = Object.assign({
                    user_id: this.$store.state.user.user.user_id
                }, this.form1, this.form2);

                this.$http.post('/main/finish_register', body).then((res) => {
                    this.loading2 = false;

                    if (res.data.code === 0) {
                        this.active = 2;
                    } else {
                        this.active = 3;
                    }
                }).catch((e) => {
                    this.loading2 = false;
                    this.$message.error(e.message || 'share.request_error');
                });
            }).catch(() => {});
        },
        logout() {
            // 清除缓存的token
            this.$store.dispatch('user/removeToken').then(() => {
                location.replace('/login'); // 这样跳转避免再次登录重复注册动态路由
            });

            localStorage.removeItem("token_collect");
        }
    }
}
